import {
  FormattedHTMLMessage,
  FormattedMessage,
  injectIntl
} from "gatsby-plugin-intl";
import React from "react";
import Helmet from "react-helmet";
import Container from "../components/Container";
import FooterBackground from "../components/FooterBackground";
import {
  circleSmall, ixfield, ixmanager, ixsolve, loader,
  logoEu,
  logoTheCountryForTheFuture,
  software1, software1_2x,
  software2, software2_2x,
  software3, software3_2x,
  software4, software4_2x,
  software5, software5_2x,
  software6, software6_2x,
  target,
  waveHorizontalLarge,
  waveHorizontalSmall,
  waveVerticalLarge,
  waveVerticalSmall
} from "../components/Images";
import Layout from "../components/Layout";
import Row from "../components/Row";
import CTA from "../partials/CTA";
import styled from "../styling/styled";
import { theme } from "../styling/theme";

const Content = styled.div`
  padding-top: 42px;
  padding-bottom: 42px;
  ${[theme.media.maxM]} {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  img.logo {
    height: 30px;
    margin-top: 8px;
    display: block;
  }
`;

const LegalLogos = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding: 0 0 20px;
  & > * {
    height: 70px;
    margin-right: 10px;
  }
`;

const Content1 = styled(Content)`
  background-size: 160px 17px;
  background-image: url(${waveHorizontalLarge}), url(${waveHorizontalLarge});
  background-repeat: no-repeat;
  background-position: left calc(${theme.container.background} - 240px) top
      115px,
    right calc(${theme.container.background} - 180px) bottom 70px;
  ${[theme.media.maxM]} {
    padding-top: 75px;
  }
`;

const Content2 = styled(Content)`
  background-size: 17px 17px;
  background-image: url(${circleSmall});
  background-repeat: no-repeat;
  background-position: left calc(${theme.container.background} - 100px) top 75px;
`;

const Content3 = styled(Content)`
  background-size: 17px 17px, 17px 47px;
  background-image: url(${circleSmall}), url(${waveVerticalSmall});
  background-repeat: no-repeat;
  background-position: right calc(${theme.container.background} - 160px) top
      75px,
    left calc(${theme.container.background} - 100px) top 95px;
`;

const Content4 = styled(Content)`
  background-size: 30px 30px, 17px 17px;
  background-image: url(${loader}), url(${circleSmall});
  background-repeat: no-repeat;
  background-position: right calc(${theme.container.background} + 60px) top 45px,
    left calc(${theme.container.background} - 70px) bottom 5px;
`;

const Funding = styled.div`
  font-size: 12px;
  max-width: 400px;
  margin-top: 80px;
`;

const Content6 = styled(Content)`
  padding-bottom: 175px;
  ${[theme.media.maxM]} {
    padding-bottom: 100px;
  }
  background-size: 17px 160px, 28px 28px, 47px 17px;
  background-image: url(${waveVerticalLarge}), url(${target}),
    url(${waveHorizontalSmall});
  background-repeat: no-repeat;
  background-position: left calc(${theme.container.background} - 140px) top,
    left calc(${theme.container.background} - 20px) bottom 180px,
    right calc(${theme.container.background} - 100px) top 40px;
  ${[theme.media.maxM]} {
    padding-top: 75px;
  }
`;

const Content7 = styled(Content)`
  padding-bottom: 175px;
`;


const RowSoftware = styled(Row)`
  align-items: center;
  gap: 30px;
  ${[theme.media.maxM]} {
    gap: 0;
    align-items: flex-start;
  }
`;

const RowHeader = styled(RowSoftware)`
  position: relative;
`;

const ColText = styled.div`
  max-width: calc(50% + 30px);
  flex-basis: calc(50% + 30px);
  ${[theme.media.maxM]} {
    max-width: 80%;
    flex-basis: 80%;
  }
`;

const ColProductText = styled(ColText)`
  ${[theme.media.maxM]} {
    max-width: 100%;
    flex-basis: 100%;
  }
`;

const ColImage = styled.div`
  max-width: calc(50% - 60px);
  flex-basis: calc(50% - 60px);
  ${[theme.media.maxM]} {
    max-width: 20%;
    flex-basis: 20%;
  }
`;

const ColProductImage = styled(ColImage)`
  ${[theme.media.maxM]} {
    max-width: 240px;
    flex-basis: 240px;
    order: -1;
    margin-bottom: 30px;
  }
`;

const Image = styled.img`
  display: block;
`;

const ImageHeader = styled.img`
  ${[theme.media.maxM]} {
    position: absolute;
    top: -80px;
    right: -80px;
    width: 50vw;
    z-index: -1;
  }
  ${[theme.media.maxS]} {
    right: 0px;
  }
`;

class SoftwarePage extends React.Component {
  render() {
    const intl = this.props.intl;
    return (
      <>
        <Helmet
          title={
            intl.formatMessage({ id: "pages.software.title" }) +
            " | Eledio.com"
          }
          meta={[
            {
              name: "description",
              content: intl.formatMessage({ id: "pages.software.description" })
            },
            {
              property: "og:title",
              content: intl.formatMessage({ id: "pages.software.title" })
            },
            {
              property: "og:description",
              content: intl.formatMessage({ id: "pages.software.description" })
            }
          ]}
        >
          <html lang={intl.locale} />
        </Helmet>
        <Layout>
          <Content1>
            <Container>
              <RowHeader spacing={15}>
                <ColText>
                  <h1>
                    <FormattedMessage id="pages.software.title" />
                  </h1>
                  <p>
                    <FormattedHTMLMessage id="pages.software.text" />
                  </p>
                </ColText>
                <ColImage>
                  <Image
                      src={software1}
                      srcSet={`${software1}, ${software1_2x} 2x`}
                      alt="Eledio software"
                  />
                </ColImage>
              </RowHeader>
            </Container>
          </Content1>
          <Content3>
            <Container>
              <RowSoftware spacing={15}>
                <ColProductText>
                  <h3>
                    <FormattedMessage id="pages.software.titleFirmware" />
                  </h3>
                  <p>
                    <FormattedHTMLMessage id="pages.software.textFirmware1" />
                  </p>
                </ColProductText>
                <ColProductImage>
                  <Image
                    src={software2}
                    srcSet={`${software2}, ${software2_2x} 2x`}
                    alt="Eledio Device Firmware image"
                  />
                </ColProductImage>
              </RowSoftware>
            </Container>
          </Content3>
          <Content4>
            <Container>
              <RowSoftware spacing={15}>
                <ColProductImage>
                  <Image
                    src={software3}
                    srcSet={`${software3}, ${software3_2x} 2x`}
                    alt="Eledio Cloud image"
                  />
                </ColProductImage>
                <ColProductText>
                  <h3>
                    <FormattedMessage id="pages.software.titleCloud" />
                  </h3>
                  <p>
                    <FormattedHTMLMessage id="pages.software.textCloud1" />
                  </p>
                </ColProductText>
              </RowSoftware>
            </Container>
          </Content4>
          <Content>
            <Container>
              <RowSoftware spacing={15}>
                <ColProductText>
                  <h3>
                    <img src={ixmanager} alt="iXmanager" className="logo" />
                  </h3>
                  <p>
                    <FormattedHTMLMessage id="pages.software.textIxmanager1" />
                  </p>
                  <p>
                    <FormattedHTMLMessage id="pages.software.textIxmanager2" />
                  </p>
                  <p>
                    <FormattedHTMLMessage id="pages.software.textIxmanager3" />
                  </p>
                </ColProductText>
                <ColProductImage>
                  <Image
                    src={software4}
                    srcSet={`${software4}, ${software4_2x} 2x`}
                    alt="Eledio iXmanager"
                  />
                </ColProductImage>
              </RowSoftware>
            </Container>
          </Content>
          <Content6>
            <Container>
              <RowSoftware spacing={15}>
                <ColProductImage>
                  <Image
                    src={software5}
                    srcSet={`${software5}, ${software5_2x} 2x`}
                    alt="Machine Learning and Artificial Intelligence image"
                  />
                </ColProductImage>
                <ColProductText>
                  <h3>
                    <img src={ixfield} alt="iXfield" className="logo" />
                  </h3>
                  <p>
                    <FormattedHTMLMessage id="pages.software.textIxfield1" />.
                  </p>
                  <p>
                    <FormattedHTMLMessage id="pages.software.textIxfield2" />
                  </p>
                  <p>
                    <FormattedHTMLMessage id="pages.software.textIxfield3" />
                  </p>
                  <p>
                    <FormattedHTMLMessage id="pages.software.textIxfield4" />
                  </p>
                </ColProductText>
              </RowSoftware>
            </Container>
          </Content6>
          <Content7>
            <Container>
              <RowSoftware spacing={15}>
                <ColProductText>
                  <h3>
                    <img src={ixsolve} alt="iXsolve" className="logo" />
                  </h3>
                  <p>
                    <FormattedHTMLMessage id="pages.software.textIxsolve1" />.
                  </p>
                  <p>
                    <FormattedHTMLMessage id="pages.software.textIxsolve2" />
                  </p>
                  <p>
                    <FormattedHTMLMessage id="pages.software.textIxsolve3" />
                  </p>
                </ColProductText>
                <ColProductImage>
                  <Image
                    src={software6}
                    srcSet={`${software6}, ${software6_2x} 2x`}
                    alt="Eledio iXSolve"
                  />
                </ColProductImage>
              </RowSoftware>
              <RowSoftware spacing={15}>
                <Funding>
                  <p>
                    <FormattedHTMLMessage id="pages.software.funding" />
                  </p>
                  <LegalLogos>
                    <img src={logoEu} alt="" />
                    <img src={logoTheCountryForTheFuture} alt="" />
                  </LegalLogos>
                </Funding>
                
              </RowSoftware>
            </Container>
          </Content7>
          <CTA
            buttonLink="/contact/"
            buttonText={intl.formatMessage({ id: "pages.software.ctatext" })}
            claim={intl.formatMessage({ id: "pages.software.ctaclaim" })}
          />
          <FooterBackground />
        </Layout>
      </>
    );
  }
}

export default injectIntl(SoftwarePage);
